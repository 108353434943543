var calendar = function (selector, minTime, maxTime, interval, mobile, additionalParams) {

    var calendar = this;

    this.mobile = mobile || false;
    this.additionalParams = additionalParams || false;

    if ($.trim(minTime) == '')
        minTime = "00:00:00";

    if ($.trim(maxTime) == '')
        maxTime = "23:59:59";

    if ($.trim(interval) == '')
        interval = "00:15:00";


    this.objCalendar = selector;
    this.enablePopover = true;

    this.bindEvents = function () {

        calendar.initCalendario();

        $("#calendario_filtro_event_type, #calendario_filtro_subject").unbind("change").bind("change", function () {
            calendar.objCalendar.fullCalendar('refetchEvents');
            calendar.objCalendar.fullCalendar('refetchResources');
        });

    };

    this.defaultView = ((calendar.additionalParams && calendar.additionalParams.defaultView) ? calendar.additionalParams.defaultView : null);
    this.defaultDate = ((calendar.additionalParams && calendar.additionalParams.defaultDate) ? calendar.additionalParams.defaultDate : null);

    this.listCustomDaysDuration = ((calendar.additionalParams && calendar.additionalParams.listCustomDaysDuration) ? calendar.additionalParams.listCustomDaysDuration : 7);

    if(!calendar.defaultView && calendar.mobile || calendar.listCustomDaysDuration!=7)
        calendar.defaultView = 'listCustomDays';

    this.calendar_resources = [];

    this.initCalendario = function () {
        /* initialize the calendar */
        var date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();

        var height = $(document).height() - $("#navbar").height() - $("#breadcrumbs").height() - $("#calendar_filters").height() + $("#main-container .footer-content").height();
        $("#main-container .footer").hide();

        calendar.objCalendar.fullCalendar({
                // schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
                defaultDate: calendar.defaultDate,
                firstDay: 1,// >> change first day of week
                defaultView: calendar.defaultView ? calendar.defaultView : 'basicWeek',
                weekends: true,
                groupByDateAndResource: true,
                displayEventTime: false,
                slotDuration: interval,
                minTime: minTime,
                maxTime: maxTime,
                slotEventOverlap: true,
                agendaEventMinHeight: 20,
                nowIndicator: true,
                height: height,
                firstHour: 9,
                customButtons: {
                    addNewEvent: {
                        text: 'New',
                        click: function() {
                            calendarioEventi.openEvento(0);
                        }
                    }
                },
                views: {
                    basicWeek: {buttonText: 'Basic'},
                    listCustomDays: {
                        type: 'list',
                        duration: { days: calendar.listCustomDaysDuration }
                    },
                },
                buttonHtml: {
                    prev: '<i class="ace-icon fa fa-chevron-left"></i>',
                    next: '<i class="ace-icon fa fa-chevron-right"></i>'
                },
                header: calendar.mobile ? {
                    right: 'prev,next today addNewEvent'
                } : {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'basicWeek,listWeek,month,agendaWeek,agendaDay'
                },
                refetchResourcesOnNavigate: true,
                resources: function (callback) {
                    var view = calendar.objCalendar.fullCalendar("getView");
                    var params = {
                        start: view.start.format(),
                        end: view.end.format(),
                        view_type: view.type,
                        id_event_types: $("#calendario_filtro_event_type").val(),
                        id_subjects: $("#calendario_filtro_subject").val(),
                        mobile: calendar.mobile ? 1 : 0,
                        additionalParams: calendar.additionalParams
                    };

                    $.get('calendar_resources', params, function (data) {
                        calendar.calendar_resources = data;
                        callback(data);
                    });
                },
                resourceRender: function (resourceObj, labelTds, bodyTds) {
                    $.each(calendar.calendar_resources, function (index, obj) {
                        if (typeof obj.id != 'undefined' && typeof obj.resourceColor != 'undefined' && obj.id == resourceObj.id) {
                            labelTds.css('background', obj.resourceColor);
                        }
                    });

                },
                events: {
                    url: 'calendar_data',
                    type: 'GET',
                    data:
                        function () { // a function that returns an object
                            var view = calendar.objCalendar.fullCalendar("getView");
                            return {
                                view_type: view.type,
                                id_event_types: $("#calendario_filtro_event_type").val(),
                                id_subjects: $("#calendario_filtro_subject").val(),
                                mobile: calendar.mobile ? 1 : 0,
                                additionalParams: calendar.additionalParams
                            };
                        },
                    error: function () {
                        app.error('Errore recuperando gli eventi del calendario');
                    },
                },
                eventAfterAllRender: function (view) {
                    if (view.type == 'basicWeek') {
                        $(".fc-day-grid.fc-unselectable").css("max-height", $(".fc-scroller").height());
                    } else {
                        $(".fc-day-grid.fc-unselectable").css("max-height", '200px');
                    }

                },
                editable: true,
                droppable: true, // this allows things to be dropped onto the calendar !!!
                selectable: true,
                durationEditable: true,
                eventResourceEditable: false,
                eventStartEditable: true,
                eventDurationEditable: true,
                forceEventDuration: true,
                selectHelper: true,
                select:
                    function (start, end, jsEvent, view, resource) {
                        var inizio = start.format("YYYY-MM-DD H:mm:ss");
                        var fine = end.format("YYYY-MM-DD H:mm:ss");

                        calendar.openEvento(0, inizio, fine, resource);
                    },
                eventClick: function (calEvent, jsEvent, view) {
                },
                loading: function (status) {
                    app.block(status);
                },
                eventRender: function (event, element, a) {
                    var view = calendar.objCalendar.fullCalendar('getView');

                    if (typeof event.html != 'undefined') {
                        if (element.find('.fc-content').length > 0) {

                            if (view.type == 'basicWeek') {
                                var content = $("<span class='fc-time'></span><span class='fc-html'></span>");
                                element.find('.fc-content').html(content);
                                element.find('.fc-content').find(".fc-html").html(event.html);
                                if ($.trim(event.time) != '')
                                    element.find('.fc-content').find(".fc-time").html(event.time + " ");

                            } else {
                                element.find('.fc-content').html(event.html);
                            }

                        } else {
                            element.find('.fc-list-item-title').html(event.html);
                        }

                        if (view.type == 'listWeek' || view.type == 'listDay' || view.type=='listCustomDays') {
                            element.find(".fc-list-item-marker").hide();
                            element.css("background-color", event.color);
                        }

                    } else {
                        element.html(event.title);
                    }

                    element.css("border-radius", '3px');

                    function ColorLuminance(hex, lum) {
                        // validate hex string
                        hex = String(hex).replace(/[^0-9a-f]/gi, '');
                        if (hex.length < 6) {
                            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                        }
                        lum = lum || 0;
                        // convert to decimal and change luminosity
                        var rgb = "#", c, i;
                        for (i = 0; i < 3; i++) {
                            c = parseInt(hex.substr(i * 2, 2), 16);
                            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                            rgb += ("00" + c).substr(c.length);
                        }
                        return rgb;
                    }

                    var coloreBordo = '';
                    if (event.completato)
                        element.css("border", '1px solid ' + ColorLuminance(event.color, -0.2));
                    else {
                        if (view.type == 'listWeek' || view.type == 'listDay' || view.type=='listCustomDays') {
                            element.css("border-left", '7px solid red');
                        }else{
                            element.css("border", '2px solid red');
                        }

                    }

                    if (calendar.enablePopover) {
                        element.popover({
                            html: true,
                            title: event.tooltip_title,
                            content: event.tooltip,
                            trigger: 'hover',
                            placement: 'top',
                            container: 'body'
                        });
                    }
                },
                eventDragStart: function () {
                    calendar.enablePopover = false;
                },
                eventDragStop: function (data, evento, ui, resourceId) {
                    calendar.enablePopover = true;
                },
                eventDrop: function (event, delta, revertFunc, jsEvent, ui, view) {
                    if (!calendar.mobile)
                        calendar.updateEvento(event, delta, revertFunc, jsEvent, ui, view);
                },
                eventResizeStart: function (event, jsEvent, ui, view) {
                    calendar.enablePopover = false;
                },
                eventResizeStop: function (event, jsEvent, ui, view) {
                    calendar.enablePopover = true;
                },
                eventResize: function (event, delta, revertFunc, jsEvent, ui, view) {
                    if (!calendar.mobile)
                        calendar.updateEvento(event, delta, revertFunc, jsEvent, ui, view);
                }
            }
        )
        ;
    },

        this.updateEvento = function (event, delta, revertFunc, jsEvent, ui, view) {
            $.post("event/update_date", {
                id_event: event.id,
                data: event.start.format("YYYY-MM-DD"),
                inizio: event.start.format("HH:mm:ss"),
                fine: event.end.format("HH:mm:ss"),
                allDay: (event.allDay ? 1 : 0)
            }, function () {
                calendar.objCalendar.fullCalendar('refetchEvents');
            });
        },

        this.openEvento = function (id, inizio, fine, resource) {
            id = id || 0;
            if (typeof id.message != 'undefined')
                id = id.message;

            console.log("Apertura evento id: " + id, inizio, fine, resource);

            var risorsaId = 0;
            if (typeof resource != 'undefined' && typeof resource.id != 'undefined')
                risorsaId = resource.id;


            $("#myModal .modal-body").html("<image src='images/loader.gif' style='width: 15px'>");
            if (id === 0)
                $("#myModal .modal-body").load('event/create?fromModal=1&inizio=' + encodeURIComponent(inizio) + '&fine=' + encodeURIComponent(fine) + "&risorsa=" + risorsaId);
            else
                $("#myModal .modal-body").load('event/' + id + '/edit?fromModal=1');

            $("#myModal .modal-dialog").css("width", "80%");
            $("#myModal .modal-title").html("Inserisci/Modifica evento");
            $("#myModal .btn-default").html("Chiudi");
            $("#myModal .btn-primary").hide();

            $("#myModal .modal-footer .btn-danger").detach();

            $("#myModal").modal("show");
            $("#myModal").unbind('hidden.bs.modal').bind("hidden.bs.modal", function () {
                calendar.objCalendar.fullCalendar('refetchEvents');
                calendar.objCalendar.fullCalendar('refetchResources');
            });
        },

        this.initEventiDaCalendarizzare = function () {
            $.get("event/eventi_da_calendarizzare", function (data) {
                $("#eventi_da_calendarizzare").html(data);
            });
        },

        calendar.bindEvents();
};
