var formatter = {

    titled: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != undefined && cell_data != "")
            return '<span title="' + cell_data.replace('"', '') + '">' + cell_data + '</span>';
        else
            return "";
    },

    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>';
    },

    euro: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data + " €";
    },

    email: function (table, td, cell_data, row_data, row_index, col_index) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(cell_data)) {
            ret = '<a href=mailto:' + cell_data + ' title="' + cell_data.replace('"', '') + '">' + cell_data + "</a>";
        } else {
            ret = cell_data;
        }

        return ret;
    },

    time_hi: function (table, td, cell_data, row_data, row_index, col_index) {
        return moment(cell_data, "hh:mm:ss").format("HH:mm");
    },

    link_fascicolo: function (table, td, cell_data, row_data, row_index, col_index) {
        if ($.trim(cell_data) != '') {
            var data = JSON.parse(cell_data);
            return '<a title="' + data.titolo + '" target="_blank" href="file/' + data.id + '/edit">' + data.titolo + '</a>';
        } else {
            return "";
        }
    },

};